import React, { useEffect, useMemo, useState } from 'react'
import SizeSelect from './SizeSelect'
import useCustomTranslation from 'src/utils/translation'
import { useAppSelector } from 'src/store'
import { getCurrentModelGarmentSizes, getGarmentSizeLabelValue } from 'src/utils/garment'
import { Button } from 'antd'

interface MultiSizeSelect {
    garmentSizes: Models.GarmentSize[]
    onChange(newValue: Models.MultiSize[] | null, name: string): void
    garmentDefaultFilter?: string
    garmentFilters?: Models.SizeFilters[]
}

const MultiSizeSelect = React.forwardRef<HTMLDivElement, MultiSizeSelect>((props, ref) => {
    const { t } = useCustomTranslation()
    const { garmentSizes, onChange, garmentDefaultFilter, garmentFilters } = props

    const model = useAppSelector((state) => state.look.current.model)

    const [selectedValues, setSelectedValues] = useState<{ [key: string]: Models.MultiSize[] }>({})
    const [garmentFilter, setGarmentFilter] = useState<string>(garmentDefaultFilter)

    // ---- Var used to store the full possible values array ----
    const allValues = useMemo(() => {
        let result: Models.GarmentSizePossibleValues[] = []

        garmentSizes.forEach((garmentSize) => (result = result.concat(garmentSize.possible_values)))

        return result
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentSizes])

    // ---- Used to know which Select to open if we press on add to cart ----
    const fieldForRef = useMemo(() => {
        if (garmentSizes.length === 0) {
            return null
        }

        // ---- We get the first field if nothing is selected yet ----
        if (Object.keys(selectedValues).length === 0 && garmentSizes[0].fields.length !== 0) {
            return garmentSizes[0].fields[0].field
        }

        //  ---- We search for the next field in all the fields ----
        for (let i = 0; i < garmentSizes.length; i++) {
            for (let j = 0; j < garmentSizes[i].fields.length; j++) {
                if (
                    !selectedValues[garmentSizes[i].type].find(
                        (selectedValue) => selectedValue.field === garmentSizes[i].fields[j].field
                    )
                ) {
                    return garmentSizes[i].fields[j].field
                }
            }
        }

        return null
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValues, garmentSizes])

    /**
     * Handle the change of size Select
     * @param newSize New selected Size
     * @param type Type of the sizegroup
     */
    const handleOnChange = (newSize: Models.MultiSize, type: string) => {
        // ---- Application du filter ----
        const filteredNewSize = garmentFilter
            ? {
                  ...newSize,
                  filter: [garmentFilter],
              }
            : newSize

        // ---- Handle selectedValues array update ----
        let newSelectedValues = [...(selectedValues[type] || []), filteredNewSize]
        if (
            selectedValues[type]?.find(
                (selectedValue) => selectedValue.field === filteredNewSize.field
            )
        ) {
            newSelectedValues = selectedValues[type].map((selectedValue) => {
                if (selectedValue.field === filteredNewSize.field) {
                    return filteredNewSize
                }

                return selectedValue
            })
        }

        setSelectedValues({
            ...selectedValues,
            [type]: newSelectedValues,
        })

        // ---- Call props callback if it exists ----
        if (onChange) {
            onChange(newSelectedValues, 'size')
        }
    }

    // ---- Get the filtered array from allValues array according to the currentField and the garmentSize object ----
    const getFilteredPossibleValue = (
        newSelectedValues: Models.MultiSize[],
        currentField: string,
        garmentSize: Models.GarmentSize
    ) => {
        let result = allValues

        // ---- We need to filter at least according to the garment filter if it exists and we didn't select values yet ----
        if (newSelectedValues.length === 0) {
            result = result.filter((possibleValue) => {
                // ---- If it has a fiters value and it doesn't match the garmentFilter we remove it ----
                if (
                    possibleValue.filters &&
                    garmentFilter &&
                    !possibleValue.filters.includes(garmentFilter)
                ) {
                    return false
                }

                // ---- Else we keep it ----
                return true
            })
        }

        // ---- For all selectedValues we filter the possible_values according to the selectedValue ----
        newSelectedValues.forEach((selectedValue) => {
            const selectedField = selectedValue.field

            // ---- We ignore do not filter for the current field ----
            if (selectedField === currentField) {
                return
            }

            result = result.filter((possibleValue) => {
                // ---- If it has a fiters value and it doesn't match the garmentFilter we remove it ----
                if (
                    possibleValue.filters &&
                    garmentFilter &&
                    !possibleValue.filters.includes(garmentFilter)
                ) {
                    return false
                }

                // ---- If the possibleValue doesn't contain the selected field we keep it ----
                if (!possibleValue[selectedField]) {
                    return true
                }

                // ---- If the possibleValue contains the field and has the same value we keep it ----
                if (possibleValue[selectedField] === selectedValue.value) {
                    return true
                }

                // ---- Else we remove it from the array ----
                return false
            })

            // ---- We stop here if there is no filter value ----
            if (!selectedValue.filter) {
                return
            }

            // ---- We filter every field values according to the selectedValue filter value ----
            garmentSize.fields.forEach((field) => {
                // ---- We filter the field values ----
                const fieldFilteredValues = field.values.filter((value) => {
                    const valueFilterSet = new Set(value.filters)
                    return selectedValue.filter.some((filterValue) =>
                        valueFilterSet.has(filterValue)
                    )
                })

                // ---- We filter the results ----
                result = result.filter((possibleValue) => {
                    // ---- If the possibleValue doesn't contain the field we keep the value ----
                    if (!possibleValue[field.field]) {
                        return true
                    }

                    // ---- If there is a corresponding value in the fieldFilteredValues we keep it ----
                    if (
                        fieldFilteredValues.findIndex(
                            (fieldValue) => fieldValue.value === possibleValue[field.field]
                        ) !== -1
                    ) {
                        return true
                    }

                    return false
                })
            })
        })

        return result
    }

    // ---- Need to call this on mount to reset the cart values ----
    useEffect(() => {
        if (onChange) {
            onChange(null, 'size')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {garmentSizes.map((sizeGroup: Models.GarmentSize) => {
                return sizeGroup.fields.map((field) => {
                    // ---- Remove the values that are not matching the garmentFilter if both filter exist ----
                    const fieldValues = field.values.filter((value) => {
                        if (value.filters && garmentFilter) {
                            return value.filters?.includes(garmentFilter)
                        }

                        return true
                    })

                    // ---- Building options for select ----
                    const optionSize = fieldValues.map((value) => {
                        // ---- Check on the array if the value exists and is in stock so we can disable or not the option ----
                        const foundPossibleValue = getFilteredPossibleValue(
                            selectedValues[sizeGroup.type] || [],
                            field.field,
                            sizeGroup
                        ).filter(
                            (possibleValue) =>
                                possibleValue[field.field] === value.value && possibleValue.in_stock
                        )

                        return {
                            ...getGarmentSizeLabelValue(
                                value,
                                getCurrentModelGarmentSizes(),
                                model.model_display || model.model_name
                            ),
                            disabled: foundPossibleValue.length === 0,
                        }
                    })

                    return (
                        <div key={field.field} className='select--multiselect-container'>
                            <div className='select--multiselect-label'>{field.label}</div>
                            <SizeSelect
                                ref={field.field === fieldForRef ? ref : undefined}
                                name='size'
                                placeholder={
                                    field.label
                                        ? t('product.choose_label', { label: field.label })
                                        : t('product.choose_size')
                                }
                                options={optionSize}
                                translation={false}
                                onChange={(e) =>
                                    handleOnChange(
                                        {
                                            group: sizeGroup.name,
                                            field: field.field,
                                            value: e,
                                            filter: optionSize.find((option) => option.value === e)
                                                ?.filters,
                                        },
                                        sizeGroup.type
                                    )
                                }
                                value={
                                    selectedValues[sizeGroup.type]?.find(
                                        (selectedValue) => selectedValue.field === field.field
                                    )?.value
                                }
                            />
                        </div>
                    )
                })
            })}
            {garmentFilters && (
                <div className='select--multiselect--filter-container'>
                    <div>{t('product.multiselect_filter_title')}</div>
                    {garmentFilters
                        .filter((filter) => filter.value !== garmentFilter)
                        .map((filter) => (
                            <Button
                                type='text'
                                onClick={() => {
                                    setGarmentFilter(filter.value)
                                    setSelectedValues({})
                                    if (ref && typeof ref !== 'function' && ref.current) {
                                        ref.current.focus()
                                    }
                                }}
                                className='button--underlined'
                                size='small'
                                key={filter.value}
                            >
                                {filter.label || filter.value}
                            </Button>
                        ))}
                </div>
            )}
        </>
    )
})

MultiSizeSelect.displayName = 'MultiSizeSelect'

export default MultiSizeSelect
